import React from "react"
import Layout from "../components/Layout"
import Projects from "../components/Projects"
import mywork from "../constants/projectspage"

export default ( {} ) => {
  const proj = mywork
  return (
    <Layout>
      <section className="projects-page">
        <Projects projects={proj} title="all projects" />
      </section>
    </Layout>
  )
}